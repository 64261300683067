<template>
  <section class="lead__list">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <b-card>
          <b-card-title>
            <b-row>
              <b-col md="3">
                <div class="d-flex align-items-center">
                  <h3 class="page-title mb-n2">Fila de Qualificação</h3>
                  <p class="mt-2 mb-n1 ml-3 text-muted">
                    {{ totalRows | numberFilter }}
                  </p>
                </div>
              </b-col>
              <b-col>
                <b-button-toolbar
                  aria-label="Barra de Ferramentas"
                  class="d-flex flex-row-reverse"
                >
                  <b-button-group class="mx-1">
                    <b-dropdown
                      text="Exportar Oportunidades"
                      v-if="projetoSelecionado"
                      variant="success"
                    >
                      <template #button-content>
                        <b-spinner
                          v-if="exporting"
                          small
                          type="grow"
                        ></b-spinner>
                        <b-icon
                          v-else
                          icon="download"
                          aria-hidden="true"
                        ></b-icon>
                        Exportar Oportunidades
                      </template>
                      <b-dropdown-item @click="download('ods')"
                        ><i class="fa fa-file-excel-o"></i> Open Document Format
                        (.ODS)
                      </b-dropdown-item>
                      <b-dropdown-item @click="download('xlsx')">
                        <i class="fa fa-file-excel-o"></i> Microsoft Excel
                        (.XLSX)</b-dropdown-item
                      >
                      <b-dropdown-item @click="download('csv')">
                        <i class="fa fa-file-excel-o"></i> Comma-Separated
                        Values (.CSV)</b-dropdown-item
                      >
                      <b-dropdown-item @click="download('pdf')">
                        <i class="fa fa-file-pdf-o"></i> Portable Document
                        Format (.PDF)</b-dropdown-item
                      >
                    </b-dropdown>
                    <b-button
                      v-if="
                        $store.getters['auth/hasPermission']('Importar Base')
                      "
                      variant="primary"
                      class="ml-1 border--solid"
                      :to="{ name: 'LeadImport' }"
                    >
                      <b-icon icon="upload" aria-hidden="true"></b-icon>
                      Importar Lote
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-card-title>

          <b-row>
            <b-col>
              <projeto-select></projeto-select>
            </b-col>
            <b-col>
              <label class="sr-only">Status</label>
              <multiselect
                v-model="filtros.lead_status"
                :options="leadStatus"
                :multiple="false"
                placeholder="Selecionar Status"
                label="nome"
                track-by="id"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
                :allow-empty="true"
              >
                <template slot="noOptions">Digite para pesquisar.</template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <i
                      v-if="props.option.lead_status_tipo_id == 1"
                      class="icon-check text-success"
                    ></i>
                    <i
                      v-if="props.option.lead_status_tipo_id == 2"
                      class="icon-close text-danger"
                    ></i>
                    <i
                      v-if="props.option.lead_status_tipo_id == 3"
                      class="icon-clock text-warning"
                    ></i>
                    <span class="option__title ml-1">{{
                      props.option.nome
                    }}</span>
                  </div>
                </template>
              </multiselect>
            </b-col>
            <!--
            <b-col>
              <label class="sr-only">Tag</label>
              <multiselect
                :value="empresaFiltros.estados"
                @input="atualizarEstados"
                :options="estados"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                placeholder="Selecionar estados "
                label="nome"
                track-by="id"
                selectLabel="Enter para selecionar"
                selectedLabel="Selecionado"
                deselectLabel="Enter para remover"
              >
                <template slot="noOptions">Lista está vazia.</template>
              </multiselect>
            </b-col> -->
          </b-row>

          <b-row v-if="selectedRows.length > 0">
            <b-col class="text-right mt-2">
              <!-- <b-button
                variant="primary"
                class="my-2 h-100"
                ><strong>{{ selectedRows.length }} contatos</strong> selecionado
                <i class="mdi mdi-whatsapp"></i>
                </b-button
              > -->

              <b-dropdown id="ddown7" :text="contatoLength" variant="primary">
                <b-dropdown-item v-b-modal.modal-send-whatsapp>
                  <span class="text-success"
                    ><i class="mdi mdi-whatsapp"></i> Enviar Mensagem
                    WhatsApp</span
                  >
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>

          <b-table
            ref="selectableTable"
            show-empty
            stacked="md"
            :items="search"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filtros"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            responsive
            :busy="loadingTable"
            v-if="forceRender"
            :selectable="true"
            @row-selected="onRowSelected"
            select-mode="multi"
          >
            <!-- selected-variant="info" -->

            <template #table-busy>
              <div class="text-center text-white my-2 loadding-white-mode">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #head(selected)>
              <b-form-checkbox size="md" @change="selectAllRows">
              </b-form-checkbox>
            </template>

            <!-- <template #cell(selected)="row">
              <b-form-checkbox
                size="md"
                :checked="row.rowSelected"
                @change="selectRow(row)"
              >
              </b-form-checkbox>
            </template> -->

            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true" class="text-success">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>

            <template #cell(nome)="row">
              <span class="text-wrap name__strong">
                {{ row.item.contato.nome }}
              </span>
            </template>

            <template #cell(razao_social)="row">
              <span class="text-wrap">
                <router-link
                  v-if="row.item.contato.empresa"
                  :to="{
                    name: 'EmpresaView',
                    params: { id: row.item.contato.empresa.id },
                  }"
                >
                  <template v-if="row.item.contato.empresa.nome_fantasia">
                    {{ row.item.contato.empresa.nome_fantasia }}
                  </template>
                  <template v-else-if="row.item.contato.empresa.empresa_base">
                    {{ row.item.contato.empresa.empresa_base.razao_social }}
                  </template>
                  <template v-else>
                    {{ row.item.contato.empresa_cnpj | cnpjFilter }}
                  </template>
                </router-link>
                <template v-else-if="row.item.cnpj">
                  {{ row.item.cnpj | cnpjFilter }}
                </template>
                <template v-else-if="row.item.contato.empresa_nome">
                  {{ row.item.contato.empresa_nome }}
                </template>
              </span>
            </template>

            <template #cell(tags)="row">
              <b-badge
                v-for="leadTag in row.item.lead_tags"
                :key="leadTag.id"
                variant="outline-secondary"
                >{{ leadTag.tag.nome }}</b-badge
              >
            </template>

            <template #cell(contatos)="row">
              <b-button-group>
                <b-button
                  :id="'telefone-popover-' + row.item.id"
                  variant="primary"
                >
                  <i class="mdi mdi-phone"></i>
                </b-button>
                <b-button
                  :id="'email-popover-' + row.item.id"
                  variant="primary"
                  class="ml-1 border--solid"
                >
                  <i class="mdi mdi-email"></i>
                </b-button>
              </b-button-group>

              <b-popover
                :target="'telefone-popover-' + row.item.id"
                triggers="hover"
                placement="top"
                variant="info"
              >
                <template #title>Telefones</template>

                <b-list-group flush :id="'telefone-popover-' + row.item.id">
                  <b-list-group-item
                    :id="'telefone-popover-' + row.item.id"
                    variant="info"
                    v-for="telefone in row.item.contato.contato_telefones"
                    :key="telefone.id"
                    style="
                      width: 200px;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span class="text-primary mx-1">{{
                      telefone.telefone
                    }}</span>

                    <i
                      v-if="telefone.valido"
                      class="icon-check text-success"
                      v-b-tooltip.hover
                      title="Válido"
                    ></i>

                    <i
                      v-else-if="telefone.valido === 0"
                      class="icon-info text-danger"
                      v-b-tooltip.hover
                      title="Inválido"
                    ></i>

                    <i
                      v-else
                      class="icon-info text-warning"
                      v-b-tooltip.hover
                      title="Validação Pendente"
                    ></i>

                    <td class="text-right ml-2">
                      <button
                        class="btn btn-success mr-2"
                        v-on:click="
                          () => {
                            call(telefone, row);
                          }
                        "
                        v-b-tooltip.hover.top="'Clique para Ligar'"
                      >
                        <i class="icon-phone"></i>
                      </button>
                    </td>
                  </b-list-group-item>
                </b-list-group>
              </b-popover>

              <b-popover
                :target="'email-popover-' + row.item.id"
                triggers="hover "
                placement="bottom"
                variant="info"
                :custom-class="
                  row.item.contato.contato_emails.length > 0
                    ? 'my-popover-class'
                    : ''
                "
              >
                <template #title>Emails</template>

                <b-list-group flush :id="'telefone-popover-' + row.item.id">
                  <b-list-group-item
                    :id="'telefone-popover-' + row.item.id"
                    variant="info"
                    v-for="email in row.item.contato.contato_emails"
                    :key="email.id"
                    class=""
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    {{ email.email }}

                    <i
                      v-if="email.valido"
                      class="icon-check text-success"
                      v-b-tooltip.hover
                      title="Válido"
                    ></i>

                    <i
                      v-else-if="email.valido === 0"
                      class="icon-info text-danger"
                      v-b-tooltip.hover
                      title="Inválido"
                    ></i>

                    <i
                      v-else
                      class="icon-info text-warning"
                      v-b-tooltip.hover
                      title="Validação Pendente"
                    ></i>
                  </b-list-group-item>
                </b-list-group>
              </b-popover>

              <!-- ------------------------------------------------------------------------------------------ -->
            </template>
            <template #cell(lead_status)="row">
              <span v-if="row.item.lead_status" class="text-wrap">
                <i
                  v-if="row.item.lead_status.lead_status_tipo_id == 1"
                  class="icon-check text-success"
                ></i>
                <i
                  v-if="row.item.lead_status.lead_status_tipo_id == 2"
                  class="icon-close text-danger"
                ></i>
                <i
                  v-if="row.item.lead_status.lead_status_tipo_id == 3"
                  class="icon-clock text-warning"
                ></i>
                {{ row.item.lead_status.nome }}
              </span>
            </template>
            <template #cell(actions)="row">
              <div class="d-flex align-items-center position-relative">
                <b-button
                  variant="primary"
                  class="mr-1"
                  title="Qualificar"
                  @click="setModalQualificacao(row.item)"
                >
                  Qualificar
                </b-button>

                <b-button
                  variant="primary"
                  class="mr-1"
                  title="Qualificar"
                  :to="{
                    name: 'LeadView',
                    params: { id: row.item.id },
                  }"
                >
                  Detalhes
                  <i class="mdi mdi-play menu-icon"></i>
                </b-button>

                <b-dropdown
                  class="btn dotted-btn-transparent pb-0"
                  text="Dropdown"
                >
                  <template slot="button-content">
                    <i class="mdi mdi-dots-vertical"></i>
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'EditContato',
                      params: { id: row.item.contato.id },
                    }"
                  >
                    <i class="mdi mdi-pencil mr-2" style="font-size: 1rem"></i>
                    Editar contato
                  </b-dropdown-item>
                  <b-dropdown-item @click="removeLead(row.item)">
                    <i class="mdi mdi-delete mr-2" style="font-size: 1rem"></i>
                    Remover
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>

            <h1>aqui</h1>
          </b-table>

          <b-row class="justify-content-md-center">
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="md"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>

    <!-- <WhatsAppSenderVue :selectedRows="selectedRows"/> -->

    <ModalQualificacao v-if="showModalQualificacao" />
    <ModalNovaOportunidade v-if="showModalNovaOportunidade" />
  </section>
</template>




<script>
import { mapMutations, mapState } from "vuex";

import ProjetoSelect from "@/components/ProjetoSelect.vue";
import LeadService from "../../services/lead.service";
import LeadStatusService from "../../services/leadstatus.service";
import ModalQualificacao from "./components/ModalQualificacao.vue";
import ModalNovaOportunidade from "./components/ModalNovaOportunidade.vue";
// import WhatsAppSenderVue from './components/WhatsAppSender.vue';

export default {
  name: "Leads",
  components: {
    ProjetoSelect,
    ModalQualificacao,
    ModalNovaOportunidade,
    // WhatsAppSenderVue
  },
  data() {
    return {
      fields: [
        "selected",

        {
          key: "nome",
          sortable: false,
          label: "Nome",
        },
        {
          key: "contato.cargo",
          sortable: false,
          label: "Cargo",
        },
        {
          key: "razao_social",
          sortable: false,
          label: "Empresa",
        },
        { key: "tags", label: "Tags", sortable: false },
        { key: "lead_status", label: "Status" },
        {
          key: "contatos",
          sortable: false,
          label: "Contatos",
        },
        { key: "actions", label: "Ações" },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      sortBy: "id",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      loadingTable: false,
      exporting: false,
      filtros: { projeto: null, lead_status: null },
      leadStatus: [],
      ctx: undefined,
      forceRender: true,

      selectedRows: [],
      selected: null,

      // selectedRows: [],
    };
  },

  mounted() {},

  computed: {
    ...mapState(["projetoSelecionado"]),
    ...mapState({
      showModalQualificacao: (state) => state.leads.showModalQualificacao,
      showModalNovaOportunidade: (state) =>
        state.leads.showModalNovaOportunidade,
    }),

    contatoLength() {
      return `${this.selectedRows.length} contato${
        this.selectedRows.length === 1 ? "" : "s"
      } selecionado${this.selectedRows.length === 1 ? "" : "s"}`;
    },
  },
  created() {
    this.loadFilters();
  },
  watch: {
    projetoSelecionado: function () {
      this.filtros.projeto = this.projetoSelecionado;
      this.loadFilters();
    },
  },
  methods: {
    ...mapMutations([
      "setCall",
      "setPhone",
      "setShowModalQualificacao",
      "setIdLead",
      "setContatoData",
    ]),

    search(ctx) {
      // console.log("chamado search ",ctx.filter.projeto.id);

      if (!ctx.filter.projeto.id) {
        return [];
      }
      this.ctx = ctx;
      let params = {
        projeto_id: ctx.filter.projeto ? ctx.filter.projeto.id : null,
        lead_status_id: ctx.filter.lead_status
          ? ctx.filter.lead_status.id
          : null,
        page: ctx.currentPage,
        perPage: ctx.perPage,
        orderBy: ctx.sortBy,
        sort: ctx.sortDesc ? "desc" : "asc",
      };

      this.loadingTable = true;

      const promise = LeadService.getAll(params);

      return promise
        .then((response) => {
          console.log(response);

          this.loadingTable = false;

          const items = response.data.data;
          this.totalRows = response.data.total;
          return items || [];
        })
        .catch(() => {
          this.loadingTable = false;
          this.totalRows = 0;
        });
    },
    download(format) {
      let params = {
        projeto_id: this.projetoSelecionado.id,
        lead_status_tipo_id: 1, // Sucesso
      };
      this.exporting = true;
      const promise = LeadService.download(format, params);
      return promise
        .then((response) => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "leads." + format;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {})
        .then(() => {
          this.exporting = false;
        });
    },
    loadFilters() {
      this.filtros.lead_status = null;
      //Carregar Status do Projeto

      if (this.projetoSelecionado) {
        LeadStatusService.getAll({
          projeto_id: this.projetoSelecionado.id,
        }).then((response) => {
          // console.log(response.data);
          // console.log(this.projetoSelecionado.id);

          this.leadStatus = response.data;
        });
      }

      this.filtros.projeto = this.projetoSelecionado;
    },
    removeLead(item) {
      // console.log(item);
      this.$swal({
        title: item.contato.empresa ? item.contato.empresa.nome_fantasia : "",
        text: "Confirma a remoção desse Lead?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.value) {
          LeadService.delete(item.id)
            .then(() => {
              this.search(this.ctx);
              this.$forceUpdate();
              this.forceRender = false;

              setTimeout(() => {
                this.forceRender = true;
              }, 1000);

              this.$swal(
                "Removido!",
                `<p> <span class="text-capitalize font-weight-bold">${
                  item.contato.empresa ? item.contato.empresa.nome_fantasia : ""
                }</span> Removido! </p> `,
                "success"
              );
            })
            .catch((e) => {
              console.log("Ocorreu um erro " + e);
            });
        }
      });

      //LeadStatusService.delete(id)
    },

    call(telefone, row) {
      this.setCall(true);
      // this.setPhone(number);

      // console.log(telefone);
      // console.log(row.item.contato);

      this.setPhone({
        numero: telefone.telefone,
        lead_id: row.item.id,
        contato_telefone_id: telefone.id,
        contato_destino: row.item.contato,
      });
    },

    onRowSelected(items) {
      this.selectedRows = items;
      // console.log(items);
    },
    selectAllRows(checked) {
      if (checked) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },

    setModalQualificacao(itemSelected) {
      this.setIdLead(itemSelected.id); // global
      this.setContatoData(itemSelected.contato); // global
      this.setShowModalQualificacao(true); // global
    },
  },
};
</script>



<style lang="scss">
</style>